import { Box, BoxProps, Image } from "@chakra-ui/react";
import { MouseEventHandler, PropsWithChildren } from "react";
import { SocialIconsNav } from "./SocialIconsNav";
import { Link } from "@chakra-ui/next-js";
import { Logo } from "~/images/svgs";

type Props = BoxProps & { onNav?: MouseEventHandler<HTMLAnchorElement> };

export const Navbar = ({
  onNav,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Box
      w="100%"
      h={{ base: "100%", lg: "100px" }}
      display={{ base: "flex" }}
      flexDirection={{ base: "row-reverse", lg: "row" }}
      justifyContent={{ base: "space-between" }}
      alignItems={{ base: "center" }}
      p={{ base: "16px 32px", lg: "24px 32px" }}
      sx={{
        backgroundColor: "rgba(250,250,250,0.45)",
        borderRadius: "20px",
      }}
      position={{ lg: "sticky" }}
      {...props}
    >
      {children}

      <Link
        href={"/"}
        w={{ base: "100%", lg: "30%" }}
        h={{ base: "80%", lg: "100%" }}
        display={{ base: "flex" }}
        alignItems="center"
        onClick={onNav}
        mr={{ md: "10%", lg: "0%" }}
      >
        <Logo h="100%" w="100%" color="grey.900" />
      </Link>
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        w="100%"
        h="100%"
        justifyContent={{ base: "flex-start", lg: "flex-end" }}
      >
        <SocialIconsNav p={{ base: "72px 36px 36px 36px", md: "10px" }} />
      </Box>
    </Box>
  );
};
