import {
  Box,
  BoxProps,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Link from "next/link";
import { NavLink } from "~/components/Buttons/Link";
import { Subheading } from "~/components/Text";
import { NewsletterButton, useGlobalContext } from "~/providers";
import { StrapiGlobalFooterComponent, getStrapiMedia } from "~/strapi";

export const Footer = () => {
  const footerImage = useBreakpointValue({
    base: "/MobileFooter.png",
    md: "/TabletFooterImage.png",
    lg: "/FooterImage.png",
  });
  const shieldImage = useBreakpointValue({
    base: "/ShieldLogo.png",
  });
  const dividerImage = useBreakpointValue({
    base: "/DividerAsset.png",
    lg: "/DesktopDividerAsset.png",
  });
  return (
    <>
      <Image
        src={footerImage}
        alt=""
        h={{ base: "395px", md: "430px" }}
        w="100vw"
      />
      <Box
        minH="maxContent"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
        bg="yellow.500"
      >
        <Link href={"/"}>
          <Image
            src={shieldImage}
            alt="shield"
            mt={{ base: "52px" }}
            h={{ base: "141px", md: "183px" }}
            w={{ base: "112px", md: "145px" }}
          />
        </Link>
        <Image
          src={dividerImage}
          alt="Divider"
          mt={{ base: "-30px", md: "-160px", lg: "-90px" }}
          w={{ base: "100%" }}
        />

        <Box
          style={{ padding: 32, paddingTop: 64, paddingBottom: 64 }}
          maxW={{ md: 600, lg: 1200 }}
        >
          <Subheading
            color="black"
            fontSize={{ base: "2xl", md: "4xl" }}
            textAlign={"center"}
            mb={12}
          >
            About the Highway 3 Consortium 
          </Subheading>
          <Text
            color="black"
            fontSize={{ base: "md", md: "lg" }}
            w="100%"
            textAlign={"center"}
            fontFamily={"heading"}
          >
            The Highway 3 consortium is a collaboration of 13 community tourism
            areas from Hope to Elkford, BC which is represented by the following
            organizations: Boundary Country Tourism, Cranbrook Tourism, Creston
            Valley Tourism, Destination Castlegar, Destination Osoyoos, District
            of Elkford, Hope, Cascades and Canyons, Manning Park Resort, Nelson
            Kootenay Lake Tourism, Similkameen Valley Planning Society, Tourism
            Fernie, Tourism Kimberley and Tourism Rossland.
          </Text>
        </Box>
        <Link target="_blank" href={"https://www.hellobc.com/ "}>
          <Image src="SNBCLogo.png" alt="" w="217px" mb={{ base: "80px" }} />
        </Link>
        <Box display={{ lg: "none" }}>
          <NewsletterButton />
        </Box>
        <FooterLinks />
      </Box>
    </>
  );
};

type PartnerProps = BoxProps & {
  title: string;
  images: StrapiGlobalFooterComponent;
};
export const Partners = ({
  title,
  images,
  children,
  ...props
}: PartnerProps) => {
  let selectImages = images.partnerImageLinks;
  if (title != "Partners") {
    selectImages = images.fundedImageLinks;
  }
  if (!selectImages) return null;
  return (
    <Box
      sx={{ m: "32px 16px" }}
      display="flex"
      flexDirection={{ base: "column" }}
      justifyContent={{ base: "center" }}
      {...props}
    >
      <Text
        fontSize="2xl"
        fontWeight="medium"
        fontStyle="italic"
        noOfLines={1}
        align="center"
        mb="6%"
      >
        {title}
      </Text>
      <Box
        display="flex"
        flexDirection={{ base: "row" }}
        justifyContent={{ base: "center" }}
        gap={{ base: 8 }}
        flexWrap={{ base: "wrap" }}
      >
        {selectImages.map((link) => {
          const { url } = getStrapiMedia(link.image);
          if (!link.href) return null;
          return (
            <Link target="_blank" href={link.href} key={url}>
              <Image
                alt={url}
                src={url}
                h={{ base: 12, lg: 88 }}
                maxW={280}
                objectFit="contain"
              />
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};
export const FooterLinks = () => {
  return (
    <Flex
      p={{ base: "64px 32px 32px 32px" }}
      w={{ base: "100%", lg: "60%" }}
      gap={{ base: 8, md: 10 }}
      wrap={{ base: "wrap" }}
      justify={{ base: "space-around" }}
      alignItems={"center"}
    >
      <NavLink fontSize="md" href={"/about-us"}>
        About Us
      </NavLink>
      <NavLink fontSize="md" href={"/contact-us"}>
        Contact Us
      </NavLink>
      <Box display={{ base: "none", lg: "flex" }}>
        <NewsletterButton />
      </Box>
      <NavLink fontSize="md" noOfLines={1} href={"/privacy-policy"}>
        Privacy Policy
      </NavLink>
      <NavLink fontSize="md" noOfLines={1} href={"https://thenumber.ca/"}>
        Made by The Number
      </NavLink>
    </Flex>
  );
};
