import {
  ButtonProps,
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
  theme,
} from "@chakra-ui/react";
import { merge } from "lodash";

type StyleProps = ButtonProps & StyleFunctionProps;

const solid = (props: StyleProps) => {
  const { colorScheme: c } = props;
  const defaultProps = defineStyle({
    ...theme.components.Button.variants!.solid({ ...props, colorMode: "dark" }),
    borderRadius: 8,
  });
  if (c === "blue") {
    const mountainStyle = defineStyle({
      background: "blue.100",
      color: "mountain.500",
      _hover: {
        background: "blue.200",
      },
      _active: {
        background: "blue.300",
      },
    });
    return merge({}, defaultProps, mountainStyle);
  }
  return defaultProps;
};

const filter = defineStyle<ButtonProps>({
  background: "white",
  color: "gray.900",
  fontFamily: "heading",
  borderRadius: 8,
  border: "2px solid",
  borderColor: "gray.300",
  _hover: {
    background: "gray.50",
  },
});
const subscribe = defineStyle<ButtonProps>({
  background: "transparent",
  color: "black",
  textTransform: "uppercase",
  fontFamily: "heading",
  padding: "12px 16px",
  borderRadius: 10,

  _hover: {
    color: "black",
    background: "green.500",
  },
});
const cookies = defineStyle<ButtonProps>({
  background: "green.500",
  color: "white",
  textTransform: "uppercase",
  fontFamily: "heading",
  padding: "12px 16px",
  borderRadius: 10,

  _hover: {
    color: "black",
    background: "green.500",
  },
});

const xl = defineStyle({
  ...theme.components.Button.sizes?.lg,
  fontSize: "xl",
});

export const Button = defineStyleConfig({
  baseStyle: {
    fontFamily: "body",
    fontWeight: "normal",
  },
  defaultProps: {
    size: "xl",
    colorScheme: "blue",
  },
  sizes: {
    xl,
  },
  variants: {
    filter,
    solid,
    subscribe,
    cookies
  },
});
