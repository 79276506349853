import { FormProvider, useForm } from "react-hook-form";
import { StrapiBlocksNewsletterFormComponent } from "../data-contracts";
import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { Input, ReactMarkdown } from "~/components";
import axios from "axios";
import { useNewsletter } from "@itinerator/next-package";
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
type FormData = {
  email: string;
  name: string;
};

export const NewsletterFormBlock = ({
  block,
}: {
  block: StrapiBlocksNewsletterFormComponent;
}) => {
  const methods = useForm<FormData>();
  const { Newsletter: newsletter_id } = block;
  const { data: newsletter, isLoading } = useNewsletter(newsletter_id);

  const {
    handleSubmit,
    formState: { isSubmitSuccessful: success, isSubmitting },
    reset,
  } = methods;

  const onSubmit = (data: FormData) =>
    axios
      .post(
        `${process.env.NEXT_PUBLIC_ITIN_API_URL}/ui/subscribe/43/${newsletter?.slug}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(() =>
        reset(
          { name: "", email: "" },
          { keepIsSubmitSuccessful: true, keepIsSubmitted: true }
        )
      );

  if (isLoading || !newsletter) return null;
  return (
    <Box display="flex" justifyContent="center">
      <FormProvider {...methods}>
        <Box
          as="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          flexGrow={1}
          maxWidth={500}
        >
          <Stack gap={4}>
            <Text fontSize={"2xl"}>{newsletter.name}</Text>
            <ReactMarkdown>{newsletter.description}</ReactMarkdown>
            <Input
              name="name"
              autoComplete="name"
              isRequired
              placeholder="Your Name..."
              label={false}
            />
            <Input
              name="email"
              type="email"
              autoComplete="email"
              isRequired
              placeholder="Your Email..."
              validation={{
                pattern: { value: EMAIL_REGEX, message: "Email not valid" },
              }}
              label={false}
            />
            <Button
              type="submit"
              isLoading={isSubmitting}
              variant="solid"
              colorScheme={success ? "green" : "blue"}
              isDisabled={success}
            >
              {success ? "Successfully Submitted" : "Submit"}
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};
