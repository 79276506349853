"use client";

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Link from "next/link";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

// Define the context and types for the consent status
type ConsentStatus = "yes" | "no" | "undecided";

interface ConsentContextType {
  consentGiven: ConsentStatus;
  handleAcceptCookies: () => void;
  handleDeclineCookies: () => void;
}

const ConsentContext = createContext<ConsentContextType | undefined>(undefined);

// Function to determine cookie consent status
export function cookieConsentGiven(): ConsentStatus {
  // Check localStorage first for a persistent 'yes' consent
  const consent = localStorage.getItem("cookie_consent");
  if (consent === "yes") return "yes";

  // Check sessionStorage for a session-based 'no' consent
  const sessionConsent = sessionStorage.getItem("cookie_consent");
  if (sessionConsent === "no") return "no";

  return "undecided";
}

// Data provider component
export const ConsentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [consentGiven, setConsentGiven] = useState<ConsentStatus>("undecided");

  useEffect(() => {
    setConsentGiven(cookieConsentGiven());
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookie_consent", "yes");
    sessionStorage.removeItem("cookie_consent"); // Clear session storage when accepted
    setConsentGiven("yes");
  };

  const handleDeclineCookies = () => {
    sessionStorage.setItem("cookie_consent", "no"); // Store rejection only for the session
    setConsentGiven("no");
  };

  return (
    <ConsentContext.Provider
      value={{ consentGiven, handleAcceptCookies, handleDeclineCookies }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

// Custom hook to use the consent context
export const useConsent = (): ConsentContextType => {
  const context = useContext(ConsentContext);
  if (context === undefined) {
    throw new Error("useConsent must be used within a ConsentProvider");
  }
  return context;
};

// Banner component
export const CookieBanner: React.FC = () => {
  const { consentGiven, handleAcceptCookies, handleDeclineCookies } =
    useConsent();
  //   if (consentGiven !== "undecided") return null;
  return (
    <Modal
      isCentered
      isOpen={consentGiven === "undecided"}
      onClose={handleDeclineCookies}
      size={{ base: "xs", md: "md" }}
      motionPreset="slideInBottom"
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent bgColor="yellow.500">
        <ModalHeader marginBottom={0}>Cookies Notice</ModalHeader>
        <ModalBody>
          We use cookies to enhance your browsing experience and help you
          discover the best travel experiences tailored to your needs. By
          accepting, you agree to our use of cookies for analytics and
          personalized content. By clicking &quot;Accept cookies&quot; you
          consent to our use of cookies. To learn more check out our{" "}
          <Link href={"/privacy-policy#5"} onClick={handleAcceptCookies}>
            Privacy Policy
          </Link>
          .
        </ModalBody>
        <ModalFooter
          display="flex"
          justifyContent="flex-end"
          gap={18}
          marginTop={10}
        >
          <Button
            type="submit"
            variant="cookies"
            onClick={handleDeclineCookies}
          >
            Reject
          </Button>
          <Button type="submit" variant="cookies" onClick={handleAcceptCookies}>
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
