import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { IconMail } from "@tabler/icons-react";
import axios from "axios";
import { useRouter } from "next/router";
import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "~/components";
import { Crow } from "~/images/svgs";

type FormData = {
  email: string;
  name: string;
};

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const NewsletterContext = createContext<
  | {
      isOpen: boolean;
      onOpen: () => void;
      onClose: () => void;
    }
  | undefined
>(undefined);

export const NewsletterProvider = ({ children }: PropsWithChildren) => {
  const { asPath, replace, pathname, query, ...rest } = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => replace({ pathname, query }, undefined, { shallow: true }),
  });

  useEffect(() => {
    if (asPath.split("#")[1] === "newsletter") onOpen();
  }, [asPath, onOpen]);
  return (
    <NewsletterContext.Provider value={{ isOpen, onOpen, onClose }}>
      {children}
      <NewsletterModal />
    </NewsletterContext.Provider>
  );
};

export const useNewsletter = () => {
  const context = useContext(NewsletterContext);
  if (!context)
    throw new Error("useNewsletter must be used inside a NewsletterProvider");
  return context;
};

export const NewsletterModal = () => {
  const { isOpen, onClose } = useNewsletter();

  const methods = useForm<FormData>();
  const {
    handleSubmit,
    formState: { isSubmitSuccessful: success, isSubmitting },
  } = methods;

  const onSubmit = (data: FormData) =>
    axios.post("https://admin.itinerator.ca/ui/subscribe/43/main", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

  return (
    <FormProvider {...methods}>
      <Modal
        size="xs"
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent bgColor="yellow.500">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {!success
                ? "Sign up for our newsletter!"
                : "Thank you for signing up for the Highway 3 Newsletter! "}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {!success ? (
                <Form />
              ) : (
                <Text>A confirmation email has been sent to your inbox. </Text>
              )}
            </ModalBody>
            <ModalFooter display="flex" justifyContent="center">
              {!success && (
                <Button
                  type="submit"
                  variant="subscribe"
                  rightIcon={<Crow />}
                  isLoading={isSubmitting}
                >
                  Subscribe
                </Button>
              )}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};

const Form = () => {
  return (
    <Stack gap={4}>
      <Input
        name="name"
        autoComplete="name"
        isRequired
        placeholder="Your Name..."
        label={false}
      />
      <Input
        name="email"
        type="email"
        autoComplete="email"
        isRequired
        placeholder="Your Email..."
        validation={{
          pattern: { value: EMAIL_REGEX, message: "Email not valid" },
        }}
        label={false}
      />
    </Stack>
  );
};

export const NewsletterButton = () => {
  const { onOpen } = useNewsletter();
  return (
    <Button variant="subscribe" rightIcon={<Crow />} onClick={onOpen}>
      Subscribe
    </Button>
  );
};

export const NewsletterIconButton = () => {
  const { onOpen } = useNewsletter();
  return (
    <Tooltip variant="primary" label="Newsletter">
      <IconMail size={32} stroke={1.5} color="black" onClick={onOpen} />
    </Tooltip>
  );
};
