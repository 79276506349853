import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import { ItineratorSimpleRegion } from "@itinerator/next-package";
import { Link } from "@chakra-ui/next-js";
import { MarkdownOrHtml } from "../Text";

type MapCommunityCardProps = {
  region: ItineratorSimpleRegion;
};
export const MapCommunityCard = ({ region }: MapCommunityCardProps) => {
  const image = region.lead_image?.url;
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row-reverse" }}
      alignItems={{ base: "flex-start", lg: "center" }}
      w={{ base: "304px", lg: "563px" }}
    >
      <Image
        w={{ base: "304px", lg: "224px" }}
        h={{ base: "200px", lg: "300px" }}
        src={image}
        fallbackSrc="/StopFillerImage.jpg"
        alt="Stop Image"
        objectFit="cover"
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={{ base: "space-between" }}
        p="24px"
        gap="16px"
      >
        {region.name && (
          <Heading fontSize={{ base: "xl", lg: "3xl" }}>{region.name}</Heading>
        )}
        {!!region.short_description && (
          <MarkdownOrHtml
            textProps={{
              noOfLines: { base: 4 },
              fontWeight: "thin",
              fontSize: { base: "sm" },
            }}
          >
            {region.short_description}
          </MarkdownOrHtml>
        )}
        {region.id && (
          <Button
            size="sm"
            w="-moz-fit-content"
            as={Link}
            href={`communities/${region.slug}`}
          >
            Learn More
          </Button>
        )}
      </Box>
    </Box>
  );
};
