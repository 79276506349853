import { ChakraProvider } from "@chakra-ui/react";
import "@splidejs/react-splide/css/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NextApp, { AppContext, AppInitialProps } from "next/app";
import { Lexend } from "next/font/google";
import localFont from "next/font/local";
import Script from "next/script";
import "swiper/css";
import "swiper/css/free-mode";
import { Layout } from "~/components/Layout/Layout";
import {
  ConsentProvider,
  CookieBanner,
  GlobalContextProvider,
  MapLoaderProvider,
  NewsletterProvider,
  PageContextProvider,
} from "~/providers";
import { PageProps, StrapiGlobal, getStrapiGlobal } from "~/strapi";
import "~/styles/globals.css";
import { theme } from "~/theme";

const lexend = Lexend({ subsets: ["latin"] });
const greatForest = localFont({ src: "../theme/great-forest.ttf" });

type InitialProps = {
  globalData: StrapiGlobal;
};
type AppProps = PageProps<InitialProps>;

const queryClient = new QueryClient();

import { CustomAppProps } from "~/types";

const App = ({ Component, pageProps }: CustomAppProps<AppProps>) => {
  const { globalData, pageData } = pageProps;
  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-lexend: ${lexend.style.fontFamily};
            --font-greatForest: ${greatForest.style.fontFamily};
          }
        `}
      </style>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-M8F6T9R6');
        `}
      </Script>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <GlobalContextProvider value={globalData}>
            <PageContextProvider value={pageData}>
              <MapLoaderProvider>
                <NewsletterProvider>
                  <ConsentProvider>
                    <Layout
                      pageData={pageData}
                      layoutOptions={Component.layoutOptions}
                    >
                      <Component {...pageProps} />
                      <CookieBanner />
                    </Layout>
                  </ConsentProvider>
                </NewsletterProvider>
              </MapLoaderProvider>
            </PageContextProvider>
          </GlobalContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </>
  );
};

App.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps<InitialProps>> => {
  const appProps = await NextApp.getInitialProps(context);
  let globalData: StrapiGlobal;
  if (typeof window === "undefined") {
    globalData = await getStrapiGlobal();
  } else {
    throw new Error(
      "You have navigated to a page that does not have getServerSideProps or getStaticProps setup."
    );
  }
  return { ...appProps, pageProps: { globalData } };
};

export default App;
