import { Text } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownProps } from "~/types";

export const MarkdownOrHtml = ({ children, ...props }: ReactMarkdownProps) => {
  if (!children) return null;

  if (children.startsWith("<")) {
    return (
      <Text
        {...props.textProps}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  }
  return <ReactMarkdown {...props}>{children}</ReactMarkdown>;
};
