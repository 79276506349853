import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { ItineratorContent } from "@itinerator/next-package";
import { Subheading } from "./Text/Subheading";
import { ReactMarkdown } from "./Text";
import { TheWindingRoute } from "./TheWindingRoute";

type Props = {
  contents: ItineratorContent[];
  content_type_name: string;
  title?: string;
};

export const ContentLinkList = ({
  contents,
  content_type_name,
  title,
}: Props) => {
  const content = contents.find(
    (c) => c.content_type_name === content_type_name
  );

  if (!content) return null;
  return (
    <Box mt={24}>
      <SimpleGrid columns={{ base: 1, lg: 2 }} rowGap={16}>
        <TheWindingRoute
          h={195}
          ml={{ base: 104, lg: 152 }}
          order={{ base: 0, lg: 1 }}
        />
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          maxW={{ base: "85vw", md: "75vw" }}
          borderBottom="3px solid"
          borderColor="green.500"
          pl={{ base: "40px", md: "104px" }}
        >
          <Subheading fontSize={{ base: "4xl", md: "4xl" }} w="100%">
            {title || `Annual events in this community`}
          </Subheading>
        </Box>
      </SimpleGrid>
      <Flex
        align="center"
        justify={{ base: "flex-start", lg: "center" }}
        px={24}
      >
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          columnGap={24}
          rowGap={6}
          py={20}
        >
          <ReactMarkdown
            linkProps={{
              color: "gray.900",
              fontFamily: "heading",
              fontSize: "3xl",
              fontWeight: "normal",
            }}
            format_children={false}
          >
            {content.description}
          </ReactMarkdown>
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
